import award from "../src/images/award.jpg";
import christmas from "../src/images/christmas.jpg";
import mixing from "../src/images/mixing.jpg";
import moreParty from "../src/images/moreParty.jpg";
import partay from "../src/images/partay.jpg";
import party from "../src/images/party.jpg";
import serious from "../src/images/serious.jpg";
import smiles from "../src/images/smiles.jpg";
import smiley from "../src/images/smiley.jpg";
import chinny from "../src/images/chinny.jpg";
import artistic from "../src/images/artistic.jpg";
import review from "../src/images/review.jpg";

export const pictures = [
  award,
  christmas,
  mixing,
  moreParty,
  partay,
  party,
  serious,
  smiles,
  smiley,
  chinny,
  artistic,
  review,
];
